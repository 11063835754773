/*
  TextMate Twilight Theme for.highlighter-coderay syntax highlighter.
  by: russbrooks.com
*/
figure.code, .highlighter-coderay {
  pre {
    background-color: #000;
    margin: 0px;

    code {
      color: #F8F8F8;
      background-color: #000;
    }
  }
}

figure.highlighter-coderay { }
span.highlighter-coderay { white-space: pre; border: 0px; padding: 2px }

figure.highlighter-coderay table {
  border-collapse: collapse; width: 100%; padding: 2px;
  td {
    padding: 1em 0.5em;
    vertical-align: top;
  }
}

.highlighter-coderay .line-numbers, .highlighter-coderay .no {
  background-color: #ECECEC;
  color: #AAA;
  text-align: right;
}

.highlighter-coderay .line-numbers a {
  color: #AAA;
}

.highlighter-coderay .line-numbers tt { font-weight: bold }
.highlighter-coderay .line-numbers .highlighted { color: red }
.highlighter-coderay .line { display: block; float: left; width: 100%; }
.highlighter-coderay span.line-numbers { padding: 0px 4px }
.highlighter-coderay .code { width: 100% }

ol.highlighter-coderay { font-size: 10pt }
ol.highlighter-coderay li { white-space: pre }

.highlighter-coderay .code pre { overflow: auto }
.highlighter-coderay .debug { color:white ! important; background:blue ! important; }

.highlighter-coderay .annotation { color:#007 }
.highlighter-coderay .attribute-name { color:#f08 }
.highlighter-coderay .attribute-value { color:#700 }
.highlighter-coderay .binary { color:#509; font-weight:bold }
.highlighter-coderay .comment  { color:#5F5A60;}
.highlighter-coderay .char { color:#04D }
.highlighter-coderay .char .content { color:#04D }
.highlighter-coderay .char .delimiter { color:#039 }
.highlighter-coderay .class { color:#9B703F; font-weight:bold }
.highlighter-coderay .complex { color:#A08; font-weight:bold }
.highlighter-coderay .constant { color:#9B5C2E; }
.highlighter-coderay .color { color:#0A0 }
.highlighter-coderay .class-variable { color:#7587A6 }
.highlighter-coderay .decorator { color:#B0B; }
.highlighter-coderay .definition { color:#099; font-weight:bold }
.highlighter-coderay .directive { color:#088; font-weight:bold }
.highlighter-coderay .delimiter { color:black }
.highlighter-coderay .doc { color:#970 }
.highlighter-coderay .doctype { color:#34b }
.highlighter-coderay .doc-string { color:#D42; font-weight:bold }
.highlighter-coderay .escape  { color:#666; font-weight:bold }
.highlighter-coderay .entity { color:#800; font-weight:bold }
.highlighter-coderay .error { color:#F00; }
.highlighter-coderay .exception { color:#C00; font-weight:bold }
.highlighter-coderay .filename { color:#099; }
.highlighter-coderay .function { color:#dacf85; }
.highlighter-coderay .global-variable { color:#9a859c; font-weight:bold }
.highlighter-coderay .hex { color:#058; font-weight:bold }
.highlighter-coderay .integer  { color:#ddf2a3; }
.highlighter-coderay .include { color:#B44; font-weight:bold }
.highlighter-coderay .inline { color: #DAEFA3 }
.highlighter-coderay .inline .inline {  }
.highlighter-coderay .inline .inline .inline {  }
.highlighter-coderay .inline .inline-delimiter { color: #DAEFA3; }
.highlighter-coderay .inline-delimiter { color: #D14; }
.highlighter-coderay .important { color:#f00; }
.highlighter-coderay .interpreted { color:#B2B; font-weight:bold }
.highlighter-coderay .instance-variable { color:#7587A6 }
.highlighter-coderay .label { color:#970; font-weight:bold }
.highlighter-coderay .local-variable { color:#963 }
.highlighter-coderay .octal { color:#40E; font-weight:bold }
.highlighter-coderay .operator { }
.highlighter-coderay .predefined-constant {  font-weight:bold }
.highlighter-coderay .predefined { color:#369; font-weight:bold }
.highlighter-coderay .preprocessor { color:#579; }
.highlighter-coderay .pseudo-class { color:#00C; font-weight:bold }
.highlighter-coderay .predefined-type { color:#074; font-weight:bold }
.highlighter-coderay .reserved, .keyword  { color:#CDA869; font-weight:bold }

.highlighter-coderay .key { color: #808; }
.highlighter-coderay .key .delimiter { color: #606; }
.highlighter-coderay .key .char { color: #80f; }
.highlighter-coderay .value { color: #088; }

.highlighter-coderay .regexp .content { color:#808 }
.highlighter-coderay .regexp .delimiter { color:#404 }
.highlighter-coderay .regexp .modifier { color:#C2C }
.highlighter-coderay .regexp .function  { color:#404; font-weight: bold }

.highlighter-coderay .string { color: #8F9D6A; }
.highlighter-coderay .string .string {  }
.highlighter-coderay .string .string .string {  }
.highlighter-coderay .string .content { color: #8F9D6A; }
.highlighter-coderay .string .char { color: #8F9D6A; }
.highlighter-coderay .string .delimiter { color: #8F9D6A; }

.highlighter-coderay .shell { color:#D14 }
.highlighter-coderay .shell .content { }
.highlighter-coderay .shell .delimiter { color:#D14 }

.highlighter-coderay .symbol { color:#CF6A4C }
.highlighter-coderay .symbol .content { color:#A60 }
.highlighter-coderay .symbol .delimiter { color:#630 }

.highlighter-coderay .tag { color:#070 }
.highlighter-coderay .tag-special { color:#D70; font-weight:bold }
.highlighter-coderay .type { color:#339; font-weight:bold }
.highlighter-coderay .variable  { color:#036 }

.highlighter-coderay .insert { background: #afa; }
.highlighter-coderay .delete { background: #faa; }
.highlighter-coderay .change { color: #aaf; background: #007; }
.highlighter-coderay .head { color: #f8f; background: #505 }

.highlighter-coderay .insert .insert { color: #080; font-weight:bold }
.highlighter-coderay .delete .delete { color: #800; font-weight:bold }
.highlighter-coderay .change .change { color: #66f; }
.highlighter-coderay .head .head { color: #f4f; }
