/*
 * main header
 */
body > header {
  h1 {
    margin-top: 0;
  }
  nav {
    ol {
      padding: 0;
      margin: 5px 0;
      li {
        display: inline;
        padding: 0;
        margin: 0;
        list-style-type: none;
        a {
          color: $linkColor;
          text-decoration: none;
          font-family: $fontFamilySerif;
        }
        a:visited {
          color: $linkColor;
        }
      }
      li:after {
        content: "|";
      }
      li:last-of-type:after {
        content: "";
      }
    }
  }
}

/*
 * content
 */
main {
  header {
    padding: 20px 0;
  }
}

section, .clear {
  clear: both;
}

ul.post-list {
  margin-left: 0;
  padding-left: 0;
  li {
    list-style-type: none;
    margin-top: 20px;
    font-size:  20px;
    a {
      text-decoration: none;
      color: $baseTextColor;
    }
    a:hover {
      text-decoration: none;
      color: $baseTextColor;
    }
  }
}

.meta {
  margin: 0 0 10px 0;
  font-style: italic;
  font-size: 14px;
  a {
    text-decoration: none;
    color: $baseTextColor;
  }
  a:hover {
    text-decoration: none;
    color: $baseTextColor;
  }
}

/*
 * floated elements (small images)
 */
.left {
  float: left;
  margin-right: 20px;
}
.right {
  float: right;
  margin-left: 20px;
}

/*
 * Footnotes
 */
footer.footnotes {
  border-top: 1px solid #eee;
  font-size: 75%;
  line-height: 1.25em;
}

.footnotes a, sup a {
  text-decoration: none;
}


/*
 * Figures / Images
 */

img {
  max-width: 748px;
}

img.small {
  max-width: 280px;
  margin: 20px 0;
}

img.left {
  margin-right: 40px;
}
img.right {
  margin-left: 40px;
}

figure {
  margin-left: 0;
  margin-right: 0;
}

figure.image {
  margin-top: 30px;
  text-align: center;
  img {
    margin-bottom: 0;
  }

  /* Keep images centered, and allow images wider than the main
     text column to break out. */
  img.big {
    margin: 0 auto;
    display: block;
    height: auto;
    padding: 0.6em 0;
    /* Centers an image by (1) pushing its left edge to the
       center of its container and (2) shifting the entire image
       in the opposite direction by half its own width.
       Works for images that are larger than their containers. */
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%); /* for Safari and iOS */
    -ms-transform: translateX(-50%); /* for IE9 */
    transform: translateX(-50%);
  }
}

figure.code {
  figcaption {
    text-align: center;
    font-size: 13px;
    line-height: 2em;
    text-shadow: 0px 1px 0px #CBCCCC;
    color: #474747;
    font-weight: normal;
    margin-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    @include gradient(#eeeeee, #a0a0a0);
    border-width: 1px 1px 0px;
    border-style: solid solid none;
    -moz-border-top-colors: none;
    -moz-border-right-colors: none;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    border-image: none;
    border-color: #CBCBCB #A5A5A5 -moz-use-text-color;
  }
}

/*
 * pagination
 */
nav.pagination {
  position: relative;
  margin: 30px auto 0 auto;
  font-size: 12px;
  text-align: center;
  width: 100%;

  .older-posts, .newer-posts {
    padding: 0 10px;
    color: $baseTextColor;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .older-posts:after {
    content: " →";
  }
  .newer-posts:before {
    content: "← ";
  }
}

/*
 *
 */
nav.prev-next {
  margin: 50px auto 0 auto;
  font-size: 12px;
  ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    li {
      span {
        padding-top: 5px;
        display: block;
        margin-right: 10px;
        width: 70px;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: right;
        float: left;
      }
      span:after {
        content: ":";
      }
      a {
        color: $baseTextColor;
        text-decoration: none;
        font-size: 16px;
        font-style: italic;
      }
    }
  }
}


/*
 * Comments
 */
section.comments {
  h1 {
    font-size: 1.5em;
  }
  margin-top: 40px;
  border-top: 1px solid #eee;

  article {
    font-size: 16px;
    margin: 30px 0;
    header {
      line-height: 1em;
      padding: 0;
      margin: 0 0 10px 0;
      h1 {
        margin: 0;
        font-size: 18px;
      }
      time {
        font-style: italic;
        font-size: 14px;
      }
    }
    header, .body {
      margin-left: 80px;
    }
    .body p {
      margin: 10px 0;
    }
    aside {
      float: left;
    }
  }
}

/*
 * Forms
 */
form.simple_form {
  margin-top: 20px;
  * {
    box-sizing: border-box;
  }
  .buttons {
    margin: 15px 10px 15px 0;
  }
  .form-group {
    margin: 0 10px 15px 0;
    label {
      display: block;
      font-family: $fontFamilySansSerif;
      font-size: 14px;
      font-weight: normal;
    }
    textarea.form-control {
      height: auto;
    }
  }
  .help-block {
    font-family: $fontFamilySansSerif;
    font-size: 12px;
    margin-top: 0;
  }
  .has-error .help-block {
    color: red;
  }
  .form-control, .btn {
    display: block;
    width: 100%;
    height: 34px;
    border: 1px solid #bbb;
    font-size: 14px;
    background: #fff;
    padding: 6px 12px;
  }
  .btn {
    height: 36px;
    border: none;
    background: #3a7cbe;
    color: #fff;
    font-weight: bold;
  }
}

input#query {
  display: block;
  width: 100%;
  height: 34px;
  border: 1px solid #bbb;
  font-size: 14px;
  background: #fff;
  padding: 6px 12px;
  margin: 30px 0 40px 0;
}
/*
 * Alerts
 */
.flash {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.flash.notice {
  color: #3C763D;
  background-color: #DFF0D8;
  border-color: #D6E9C6;
}
.flash.info {
  color: #31708F;
  background-color: #D9EDF7;
  border-color: #BCE8F1;
}
.flash.warning{
  color: #8A6D3B;
  background-color: #FCF8E3;
  border-color: #FAEBCC;
}
.flash.error {
  color: #A94442;
  background-color: #F2DEDE;
  border-color: #EBCCD1;
}

/*
 * main footer
 */
body > footer {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  h1 {
    font-size: 1em;
    margin: 20px 0 10px 0;
  }
  ul, li {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
  }
  section.bottom {
    font-size: 12px;
    img.bold-logo {
      width: 39px;
      height: 10px;
      margin-left: 5px;
    }
  }
  .third {
    padding-right: 20px;
  }
}

