/* =========== */
/* All screens */
/* =========== */

@include unsemantic-grid-placeholders;

body > header,
body > main,
body > footer {
  @extend %grid-container;
}

body {
  main > article, main > div {
    max-width: 600px;
    margin: 0 auto;
  }
}

.half,
.third,
.two-thirds,
.full {
  @extend %grid-columns-all;
}

/* ===================== */
/* "Mobile" screen width */
/* ===================== */

@media screen and (max-width: $media-mobile-max) {
  @include unsemantic-grid-placeholders(mobile);
  body {
    margin-left: 20px;
    margin-right: 20px;
  }

  body > header, main h1 {
    text-align: center;
  }

  main {
    padding-left: 30px;
    padding-right: 30px;
  }

  body > footer li {
    display: inline;
    margin-right: 20px;
  }

  .half,
  .third,
  .two-thirds,
  .full {
    @extend %mobile-grid-100;
  }

  img {
    max-width: 100% !important;
  }

}

/* ====================== */
/* "Desktop" screen width */
/* ====================== */
@media screen and (min-width: $media-desktop-min) {
  @include unsemantic-grid-placeholders(desktop);

  body > header nav ol {
    float: right;
  }

  .third {
    @extend %desktop-grid-33;
  }
  .half {
    @extend %desktop-grid-50;
  }
  .two-thirds {
    @extend %desktop-grid-66;
  }
  .full {
    @extend %desktop-grid-100;
  }

}

